:root
{
	--lightFont: 300;
	--normalFont: 400;
	--mediumFont: 500;
	--boldFont	: 700;

	--normalFontSize: 13px;
	--lineHeight: 1.428;

	--space-length: 4px;

	--column-width: calc( 100% / 12 );
	--row-height: calc( 100% / 12 );
	
	--h1: 36px;
	--h2: 30px;
	--h3: 24px;
	--h4: 18px;
	--h5: 14px;
	--h6: 12px;

	--lightgray: #E0E0E0;
	--black: #1a1a1a;
	--gray: #545454;
	--silver : #b3b3b3;
	--lightblue: #1E88E5;
	--lightspacegray: #90A4AE;
	--spacegray: #37474F;
	--green: #4CAF50;
	--red: #D50000;
	--purple: #9C27B0;
	--blue: #0b3860;
	--orange: #F57C00;
	--white: #fff;
	--yellow: #FBC02D;
	--darkyellow: #FBC02D;
	--siver: #a3aaae;
	--brown: #5D4037;


	--form-label-color: #1a1a1a;
	--form-label-color-disabled: #cbcbcb;

	--form-input-background: transparent;
	--form-input-background-disabled: #f5f5f5;
	--form-input-color: #1a1a1a;
	--form-input-border: #1a1a1a;
	--form-input-border-focus: #1E88E5;
	--form-input-border-disabled: #cbcbcb;

	--form-button-background: transparent;
	--form-button-color: #1a1a1a;
	--form-button-border: #1a1a1a;
	--form-button-border-hover: #1a1a1a;
	--form-button-background-hover: #cbcbcb;

}

html, body
{
	margin:0;
	padding:0;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    
	
	font-style: normal;
	color: #1a1a1a;
	font-weight: var(--normalFont);
	line-height: var(--lineHeight);
	font-size: var(--normalFontSize);
	--fontSize: var(--normalFontSize);

    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f0f0f0;
    overflow: hidden;
    display: block;
    background: url('./../../Assets/images/bg.svg') no-repeat  center center/cover;
}

h1,.h1{ font-size: var(--h1); --fontSize: var(--h1); }
h2,.h2{ font-size: var(--h2); --fontSize: var(--h2); }
h3,.h3{ font-size: var(--h3); --fontSize: var(--h3); }
h4,.h4{ font-size: var(--h4); --fontSize: var(--h4); }
h5,.h5{ font-size: var(--h5); --fontSize: var(--h5); }
h6,.h6{ font-size: var(--h6); --fontSize: var(--h6); }

.relative{ position: relative; }
.absolute{ position: absolute; }
.fixed{ position: fixed; }
.static{ position: static; }
.sticky{ position: sticky; }

.block{ display: block; }
.inline-block{ display: inline-block; }
.inline{ display: inline; }
.flex{ display: flex; }
.inline-flex{ display: inline-flex; }
.table{ display: table; }
.table-row{ display: table-row; }
.table-cell{ display: table-cell; }
.none{ display: none; }
.hidden{ display: none !important; }

.select-none{ -moz-user-select: none; -webkit-user-select: none; -ms-user-select: none; user-select: none; cursor: defualt; }
.border-box{ -moz-box-sizing: border-box; -webkit-box-sizing: border-box; -ms-box-sizing: border-box; box-sizing: border-box; }

.flex-row{ flex-direction: row; }
.flex-row-reverse{ flex-direction: row-reverse; }
.flex-col{ flex-direction: column; }
.flex-col-reverse{ flex-direction: column-reverse; }

.flex-no-wrap{ flex-wrap: nowrap; }
.flex-wrap{ flex-wrap: wrap; }
.flex-wrap-reverse{ flex-wrap: wrap-reverse; }

.flex-initial{ flex: 0 1 auto; }
.flex-1{ flex: 1 1 0%; }
.flex-auto{ flex: 1 1 auto; }
.flex-none{ flex: none; }

.flex-grow{ flex-grow: 1; }
.flex-grow-0{ flex-grow: 0; }

.flex-shrink{ flex-shrink: 1; }
.flex-shrink-0{ flex-shrink: 0; }

.items-stretch{ align-items: stretch; }
.items-start{ align-items: flex-start; }
.items-center{ align-items: center; }
.items-end{ align-items: flex-end; }
.items-baseline{ align-items: baseline; }

.content-stretch{ align-content: stretch; }
.content-start{ align-content: flex-start; }
.content-center{ align-content: center; }
.content-end{ align-content: flex-end; }
.content-baseline{ align-content: baseline; }
.content-between{ align-content: space-between; }
.content-around{ align-content: space-around; }

.self-stretch{ align-self: stretch; }
.self-start{ align-self: flex-start; }
.self-center{ align-self: center; }
.self-end{ align-self: flex-end; }
.self-baseline{ align-self: baseline; }

.justify-start{ justify-content: flex-start; }
.justify-center{ justify-content: center; }
.justify-end{ justify-content: flex-end; }
.justify-between{ justify-content: space-between; }
.justify-around{ justify-content: space-around; }

.text-justify{ text-align: justify; }
.text-left{ text-align: left; }
.text-right{ text-align: right; }
.text-center{ text-align: center; }

.space-nowrap { white-space: nowrap; }
.space-normal { white-space: normal; }
.space-pre { white-space: pre; }

.align-baseline { vertical-align: baseline; }
.align-top { vertical-align: text-top; }
.align-bottom { vertical-align: text-bottom; }
.align-sub { vertical-align: sub; }
.align-super { vertical-align: super; }

.pointer{ cursor: pointer; }

.text-italic{ font-style: italic; }
.text-oblique{ font-style: oblique; }
.text-normal{ font-style: normal; }

.text-default{ font-weight: var(--normalFont); }
.text-light{ font-weight: var(--lightFont); }
.text-medium{ font-weight: var(--mediumFont); }
.text-bold{ font-weight: var(--boldFont); }

.text-sem{ font-size: 0.6em; --fontSize: 0.6em; }
.text-mem{ font-size: 0.75em; --fontSize: 0.75em; }
.text-lem{ font-size: 1em; --fontSize: 1em; }
.text-xlem{ font-size: 1.25em; --fontSize: 1.25em; }
.text-xxlem{ font-size: 1.50em; --fontSize: 1.50em; }

.text-hover-lightgray:hover,
.text-lightgray{ color: var(--lightgray); }
.text-hover-black:hover,
.text-black{ color: var(--black); }
.text-hover-gray:hover,
.text-gray{ color: var(--gray); }
.text-hover-lightblue:hover,
.text-lightblue{ color: var(--lightblue); }
.text-hover-green:hover,
.text-green{ color: var(--green); }
.text-hover-red:hover,
.text-red{ color: var(--red); }
.text-hover-purple:hover,
.text-purple{ color: var(--purple); }
.text-hover-blue:hover,
.text-blue{ color: var(--blue); }
.text-hover-orange:hover,
.text-orange{ color: var(--orange); }
.text-hover-white:hover,
.text-white{ color: var(--white); }
.text-hover-yellow:hover,
.text-yellow{ color: var(--yellow); }
.text-hover-silver:hover,
.text-silever{ color: var(--silver) }

.text-overflow
{
	--heightRow: var(--fontSize) * var( --lineHeight );
	overflow: hidden;
	display: block;
	height: calc( var(--heightRow) * var( --row ) );
}

.text-overflow-button input[type="checkbox"]{ display: none; }

.text-overflow-button label.text-overflow-label:after
{
	content: attr(data-more);
	cursor: pointer;
	color: #1E88E5;
	font-weight: var(--mediumFont);
}

.text-overflow-button input[type="checkbox"]:checked + label.text-overflow-label:after
{
	content: attr(data-less);
}

.opacity{ opacity: var( --opacity, 1 ); }
.opacity-hover:hover{ opacity: var( --opacity-hover, 1 ); }
.opacity-parent-hover:hover .opacity{ opacity: var( --opacity-hover, 1 ); }

.p{ padding: var( --space-length ); }
.p\:2{ padding: calc( var( --space-length ) * 2 ); }
.p\:3{ padding: calc( var( --space-length ) * 3 ); }
.p\:4{ padding: calc( var( --space-length ) * 4 ); }
.p\:5{ padding: calc( var( --space-length ) * 5 ); }
.p\:6{ padding: calc( var( --space-length ) * 6 ); }
.p\:7{ padding: calc( var( --space-length ) * 7 ); }
.p\:8{ padding: calc( var( --space-length ) * 8 ); }

.p\:r{ padding-right: var( --space-length ); }
.p\:r2{ padding-right: calc( var( --space-length ) * 2 ); }
.p\:r3{ padding-right: calc( var( --space-length ) * 3 ); }
.p\:r4{ padding-right: calc( var( --space-length ) * 4 ); }
.p\:r5{ padding-right: calc( var( --space-length ) * 5 ); }
.p\:r6{ padding-right: calc( var( --space-length ) * 6 ); }
.p\:r7{ padding-right: calc( var( --space-length ) * 7 ); }
.p\:r8{ padding-right: calc( var( --space-length ) * 8 ); }

.p\:l{ padding-left: var( --space-length ); }
.p\:l2{ padding-left: calc( var( --space-length ) * 2 ); }
.p\:l3{ padding-left: calc( var( --space-length ) * 3 ); }
.p\:l4{ padding-left: calc( var( --space-length ) * 4 ); }
.p\:l5{ padding-left: calc( var( --space-length ) * 5 ); }
.p\:l6{ padding-left: calc( var( --space-length ) * 6 ); }
.p\:l7{ padding-left: calc( var( --space-length ) * 7 ); }
.p\:l8{ padding-left: calc( var( --space-length ) * 8 ); }

.p\:t{ padding-top: var( --space-length ); }
.p\:t2{ padding-top: calc( var( --space-length ) * 2 ); }
.p\:t3{ padding-top: calc( var( --space-length ) * 3 ); }
.p\:t4{ padding-top: calc( var( --space-length ) * 4 ); }
.p\:t5{ padding-top: calc( var( --space-length ) * 5 ); }
.p\:t6{ padding-top: calc( var( --space-length ) * 6 ); }
.p\:t7{ padding-top: calc( var( --space-length ) * 7 ); }
.p\:t8{ padding-top: calc( var( --space-length ) * 8 ); }

.p\:b{ padding-bottom: var( --space-length ); }
.p\:b2{ padding-bottom: calc( var( --space-length ) * 2 ); }
.p\:b3{ padding-bottom: calc( var( --space-length ) * 3 ); }
.p\:b4{ padding-bottom: calc( var( --space-length ) * 4 ); }
.p\:b5{ padding-bottom: calc( var( --space-length ) * 5 ); }
.p\:b6{ padding-bottom: calc( var( --space-length ) * 6 ); }
.p\:b7{ padding-bottom: calc( var( --space-length ) * 7 ); }
.p\:b8{ padding-bottom: calc( var( --space-length ) * 8 ); }

.p\:h{ padding-top: var( --space-length ); padding-bottom: var( --space-length ); }
.p\:h2{ padding-top: calc( var( --space-length ) * 2 ); padding-bottom: calc( var( --space-length ) * 2 ); }
.p\:h3{ padding-top: calc( var( --space-length ) * 3 ); padding-bottom: calc( var( --space-length ) * 3 ); }
.p\:h4{ padding-top: calc( var( --space-length ) * 4 ); padding-bottom: calc( var( --space-length ) * 4 ); }
.p\:h5{ padding-top: calc( var( --space-length ) * 5 ); padding-bottom: calc( var( --space-length ) * 5 ); }
.p\:h6{ padding-top: calc( var( --space-length ) * 6 ); padding-bottom: calc( var( --space-length ) * 6 ); }
.p\:h7{ padding-top: calc( var( --space-length ) * 7 ); padding-bottom: calc( var( --space-length ) * 7 ); }
.p\:h8{ padding-top: calc( var( --space-length ) * 8 ); padding-bottom: calc( var( --space-length ) * 8 ); }

.p\:v{ padding-right: var( --space-length ); padding-left: var( --space-length ); }
.p\:v2{ padding-right: calc( var( --space-length ) * 2 ); padding-left: calc( var( --space-length ) * 2 ); }
.p\:v3{ padding-right: calc( var( --space-length ) * 3 ); padding-left: calc( var( --space-length ) * 3 ); }
.p\:v4{ padding-right: calc( var( --space-length ) * 4 ); padding-left: calc( var( --space-length ) * 4 ); }
.p\:v5{ padding-right: calc( var( --space-length ) * 5 ); padding-left: calc( var( --space-length ) * 5 ); }
.p\:v6{ padding-right: calc( var( --space-length ) * 6 ); padding-left: calc( var( --space-length ) * 6 ); }
.p\:v7{ padding-right: calc( var( --space-length ) * 7 ); padding-left: calc( var( --space-length ) * 7 ); }
.p\:v8{ padding-right: calc( var( --space-length ) * 8 ); padding-left: calc( var( --space-length ) * 8 ); }

.m{ margin: var( --space-length ); }
.m\:2{ margin: calc( var( --space-length ) * 2 ); }
.m\:3{ margin: calc( var( --space-length ) * 3 ); }
.m\:4{ margin: calc( var( --space-length ) * 4 ); }
.m\:5{ margin: calc( var( --space-length ) * 5 ); }
.m\:6{ margin: calc( var( --space-length ) * 6 ); }
.m\:7{ margin: calc( var( --space-length ) * 7 ); }
.m\:8{ margin: calc( var( --space-length ) * 8 ); }

.m\:r{ margin-right: var( --space-length ); }
.m\:r2{ margin-right: calc( var( --space-length ) * 2 ); }
.m\:r3{ margin-right: calc( var( --space-length ) * 3 ); }
.m\:r4{ margin-right: calc( var( --space-length ) * 4 ); }
.m\:r5{ margin-right: calc( var( --space-length ) * 5 ); }
.m\:r6{ margin-right: calc( var( --space-length ) * 6 ); }
.m\:r7{ margin-right: calc( var( --space-length ) * 7 ); }
.m\:r8{ margin-right: calc( var( --space-length ) * 8 ); }

.m\:l{ margin-left: var( --space-length ); }
.m\:l2{ margin-left: calc( var( --space-length ) * 2 ); }
.m\:l3{ margin-left: calc( var( --space-length ) * 3 ); }
.m\:l4{ margin-left: calc( var( --space-length ) * 4 ); }
.m\:l5{ margin-left: calc( var( --space-length ) * 5 ); }
.m\:l6{ margin-left: calc( var( --space-length ) * 6 ); }
.m\:l7{ margin-left: calc( var( --space-length ) * 7 ); }
.m\:l8{ margin-left: calc( var( --space-length ) * 8 ); }

.m\:t{ margin-top: var( --space-length ); }
.m\:t2{ margin-top: calc( var( --space-length ) * 2 ); }
.m\:t3{ margin-top: calc( var( --space-length ) * 3 ); }
.m\:t4{ margin-top: calc( var( --space-length ) * 4 ); }
.m\:t5{ margin-top: calc( var( --space-length ) * 5 ); }
.m\:t6{ margin-top: calc( var( --space-length ) * 6 ); }
.m\:t7{ margin-top: calc( var( --space-length ) * 7 ); }
.m\:t8{ margin-top: calc( var( --space-length ) * 8 ); }

.m\:b{ margin-bottom: var( --space-length ); }
.m\:b2{ margin-bottom: calc( var( --space-length ) * 2 ); }
.m\:b3{ margin-bottom: calc( var( --space-length ) * 3 ); }
.m\:b4{ margin-bottom: calc( var( --space-length ) * 4 ); }
.m\:b5{ margin-bottom: calc( var( --space-length ) * 5 ); }
.m\:b6{ margin-bottom: calc( var( --space-length ) * 6 ); }
.m\:b7{ margin-bottom: calc( var( --space-length ) * 7 ); }
.m\:b8{ margin-bottom: calc( var( --space-length ) * 8 ); }

.m\:h-auto{ margin: auto 0; }
.m\:h{ margin-top: var( --space-length ); margin-bottom: var( --space-length ); }
.m\:h2{ margin-top: calc( var( --space-length ) * 2 ); margin-bottom: calc( var( --space-length ) * 2 ); }
.m\:h3{ margin-top: calc( var( --space-length ) * 3 ); margin-bottom: calc( var( --space-length ) * 3 ); }
.m\:h4{ margin-top: calc( var( --space-length ) * 4 ); margin-bottom: calc( var( --space-length ) * 4 ); }
.m\:h5{ margin-top: calc( var( --space-length ) * 5 ); margin-bottom: calc( var( --space-length ) * 5 ); }
.m\:h6{ margin-top: calc( var( --space-length ) * 6 ); margin-bottom: calc( var( --space-length ) * 6 ); }
.m\:h7{ margin-top: calc( var( --space-length ) * 7 ); margin-bottom: calc( var( --space-length ) * 7 ); }
.m\:h8{ margin-top: calc( var( --space-length ) * 8 ); margin-bottom: calc( var( --space-length ) * 8 ); }

.m\:v-auto{ margin: 0 auto; }
.m\:v{ margin-right: var( --space-length ); margin-left: var( --space-length ); }
.m\:v2{ margin-right: calc( var( --space-length ) * 2 ); margin-left: calc( var( --space-length ) * 2 ); }
.m\:v3{ margin-right: calc( var( --space-length ) * 3 ); margin-left: calc( var( --space-length ) * 3 ); }
.m\:v4{ margin-right: calc( var( --space-length ) * 4 ); margin-left: calc( var( --space-length ) * 4 ); }
.m\:v5{ margin-right: calc( var( --space-length ) * 5 ); margin-left: calc( var( --space-length ) * 5 ); }
.m\:v6{ margin-right: calc( var( --space-length ) * 6 ); margin-left: calc( var( --space-length ) * 6 ); }
.m\:v7{ margin-right: calc( var( --space-length ) * 7 ); margin-left: calc( var( --space-length ) * 7 ); }
.m\:v8{ margin-right: calc( var( --space-length ) * 8 ); margin-left: calc( var( --space-length ) * 8 ); }

.border-width{ border-width: var( --border-width, 1px ); }
.border-width\:l{ border-left-width: var( --border-width-l, 1px ); }
.border-width\:r{ border-right-width: var( --border-width-r, 1px ); }
.border-width\:t{ border-top-width: var( --border-width-t, 1px ); }
.border-width\:b{ border-bottom-width: var( --border-width-b, 1px ); }

.border-style{ border-style: var( --border-style, solid ); }
.border-style\:l{ border-left-style: var( --border-style-l, solid ); }
.border-style\:r{ border-right-style: var( --border-style-r, solid ); }
.border-style\:t{ border-top-style: var( --border-style-t, solid ); }
.border-style\:b{ border-bottom-style: var( --border-style-b, solid ); }

.border-color{ border-color: var( --border-color, black ); }
.border-color\:l{ border-left-color: var( --border-color-l, black ); }
.border-color\:r{ border-right-color: var( --border-color-r, black ); }
.border-color\:t{ border-top-color: var( --border-color-t, black ); }
.border-color\:b{ border-bottom-color: var( --border-color-b, black ); }


.loading:after
{
	content: "";
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	bottom: 0;
	background-repeat: no-repeat;
	background-position: center center;
	/*background-image: url('/static/images/loader.gif');*/
	background-size: 48px 48px;
	background-color: rgba(255,255,255,0.75);
	z-index: 99;
}

.gradient-white-after:after
{
	content: "";
	position: absolute;
	left: 0;
	right: 0;
	top: -20px;
	height: 20px;
	background: -moz-linear-gradient(top,  rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
	background: -webkit-linear-gradient(top,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	background: linear-gradient(to bottom,  rgba(255,255,255,0) 0%,rgba(255,255,255,1) 100%);
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#00ffffff', endColorstr='#ffffff',GradientType=0 );
}

.column
{
	position: relative;
	display: block;
	height: 100%;
}

.column-1{ width: var( --column-width ); }
.column-2{ width: calc( var( --column-width ) * 2 ); }
.column-3{ width: calc( var( --column-width ) * 3 ); }
.column-4{ width: calc( var( --column-width ) * 4 ); }
.column-5{ width: calc( var( --column-width ) * 5 ); }
.column-6{ width: calc( var( --column-width ) * 6 ); }
.column-7{ width: calc( var( --column-width ) * 7 ); }
.column-8{ width: calc( var( --column-width ) * 8 ); }
.column-9{ width: calc( var( --column-width ) * 9 ); }
.column-10{ width: calc( var( --column-width ) * 10 ); }
.column-11{ width: calc( var( --column-width ) * 11 ); }
.column-12{ width: calc( var( --column-width ) * 12 ); }

.row
{
	position: relative;
	display: block;
	width: 100%;
}

.row-1{ height: var( --row-height ); }
.row-2{ height: calc( var( --row-height ) * 2 ); }
.row-3{ height: calc( var( --row-height ) * 3 ); }
.row-4{ height: calc( var( --row-height ) * 4 ); }
.row-5{ height: calc( var( --row-height ) * 5 ); }
.row-6{ height: calc( var( --row-height ) * 6 ); }
.row-7{ height: calc( var( --row-height ) * 7 ); }
.row-8{ height: calc( var( --row-height ) * 8 ); }
.row-9{ height: calc( var( --row-height ) * 9 ); }
.row-10{ height: calc( var( --row-height ) * 10 ); }
.row-11{ height: calc( var( --row-height ) * 11 ); }
.row-12{ height: calc( var( --row-height ) * 12 ); }

.full-block
{
	position:relative;
	box-sizing:border-box;-webkit-box-sizing:border-box;-moz-box-sizing:border-box;
	width:100%;
	height:100%;
}