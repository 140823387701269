$prefix: oak-;
@mixin borderBox() 
{
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}


.#{$prefix}input
{
	position: relative;

	&.#{$prefix}input-disabled
	{
		input
		{
			&:disabled
			{
				background-color: var(--form-input-background-disabled);
				border-color: var(--form-input-border-disabled);
			}
		}
		label
		{
			color: var(--form-label-color-disabled);
		}
	}
	input
	{
		display: inline-block;
		height: 32px;
		line-height: 30px;
		padding: 0 4px;
		position: relative;
		font-family: inherit;
		color: inherit;
		font-size:13px;
		width: 100%;
		outline: none;
		background-color: var(--form-input-background);
		color: var(--form-input-color);
		border: 1px solid var(--form-input-border);

		@include borderBox();
		

		&:focus
		{
			outline: 1px solid var(--form-input-border-focus);
			border-color: var(--form-input-border-focus);
			border-width: 1px;
		}

		

	/*	&:read-only { background-color: lawngreen; }
		&:read-write { background-color: blueviolet; }
		&:placeholder-shown{ background-color: orange; }
		&:default { background-color: orangered; }
		&:checked { background-color: orchid; }
		&:indeterminate { background-color: yellow; }
		&:valid { background-color: yellowgreen; }
		&:invalid { background-color: darkred; }
		&:in-range { background-color: brown; }
		&:out-of-range { background-color: burlywood; }
		&:required { background-color: grey; }
		&:optional { background-color: black; }
		&:blank { background-color: royalblue; }
		&:user-invalid { background-color: aqua; }
		&:enabled { background-color: blue; }
	*/
	
	}
		

	label
	{
		position: relative;
		font-size: 14px;
		font-weight: 600;
		line-height: 16px;
		height: 16px;
		padding: 0;
		margin: 0;
		color: var(--form-label-color);
	}
}
