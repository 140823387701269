$prefix: oak-;
@mixin borderBox() 
{
	box-sizing: border-box;
	-moz-box-sizing: border-box;
	-webkit-box-sizing: border-box;
}

@mixin selectNone() 
{
	-moz-user-select: none; 
	-webkit-user-select: none; 
	-ms-user-select: none; 
	user-select: none;
}

.#{$prefix}button
{
	display: inline-block;
	height: 32px;
	line-height: 32px;
	padding: 0 16px;
	position: relative;
	font-size:13px;
	font-weight: var(--mediumFont);
	cursor: pointer;

	background-color: var(--form-button-background);
	color: var(--form-button-color);
	border: 1px solid var(--form-button-border);

	@include borderBox();
	@include selectNone();
	

	&:hover
	{
		border-color: var(--form-button-border-hover);
		background-color: var(--form-button-background-hover);
	}

	&.#{$prefix}button-blue
	{
		--form-button-background: #1976D2;
		--form-button-color: #fff;
		--form-button-border: #1976D2;
		--form-button-border-hover: #1565C0;
		--form-button-background-hover: #1565C0;	
	}

	&.#{$prefix}button-orange
	{
		--form-button-background: #F57C00;
		--form-button-color: #fff;
		--form-button-border: #F57C00;
		--form-button-border-hover: #EF6C00;
		--form-button-background-hover: #EF6C00;	
	}

	&.#{$prefix}button-black
	{
		--form-button-background: #757575;
		--form-button-color: #fff;
		--form-button-border: #757575;
		--form-button-border-hover: #616161;
		--form-button-background-hover: #616161;	
	}

	&.#{$prefix}button-red
	{
		--form-button-background: #E53935;
		--form-button-color: #fff;
		--form-button-border: #E53935;
		--form-button-border-hover: #D32F2F;
		--form-button-background-hover: #D32F2F;	
	}

	&.#{$prefix}button-green
	{
		--form-button-background: #43A047;
		--form-button-color: #fff;
		--form-button-border: #43A047;
		--form-button-border-hover: #388E3C;
		--form-button-background-hover: #388E3C;	
	}

	&.#{$prefix}button-pink
	{
		--form-button-background: #D81B60;
		--form-button-color: #fff;
		--form-button-border: #D81B60;
		--form-button-border-hover: #C2185B;
		--form-button-background-hover: #C2185B;	
	}
	&.#{$prefix}button-empty
	{
		--form-button-background: transparent;
		--form-button-color: #1a1a1a;
		--form-button-border: transparent;
		--form-button-border-hover: transparent;
		--form-button-background-hover: transparent;	
	}

	.#{$prefix}button-loader
	{
		margin-left: -9px;
		margin-right: 8px;
	}
}
